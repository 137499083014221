.req-history-skeleton {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  &__single-request {
    border-top: 8px solid @gray;
    border-bottom: 4px solid @gray;
    -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
    -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
    box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
    border-right: none;
    border-left: none;
    width: 100%;
    margin-bottom: 24px;
    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      &__frame {
        width: 80px;
        height: 80px;
        border: 1px solid @gray;
        padding: 8px;
        margin-bottom: 8px;
        background: @light-gray;
      }
      &__description {
        padding-left: 16px;
        width: calc(100% - 80px);
        &__title {
          height: 31px;
          width: 60%;
        }
        &__property {
          font-size: 13px;
          font-weight: bold;
          &__label {
            margin-top: 5px;
            height: 16px;
            width: 40%;
            display: block;
          }
          &__label.longer {
            width: 75%;
          }
        }
      }
      &__state-block {
        background-color: @light-gray;
        width: 100%;
        padding-bottom: 12px;
        margin-top: 12px;
        height: 44px;
      }
    }
  }
}

@media (min-width: 460px) {
  .req-history-skeleton {
    &__single-request {
      &__content {
        justify-content: center;
      }
    }
  }
}

@media (min-width: 555px) {
  .req-history-skeleton {
    &__single-request {
      &__content {
        justify-content: space-around;
        &__frame {
          width: 124px;
          height: 124px;
        }
        &__description {
          padding-left: 0px;
          width: 260px;
        }
        &__state-block {
          font-size: 18px;
          svg {
            font-size: 32px;
            padding-left: 8px;
            top: 10px;
          }
        }
      }
    }
  }
}

@media @smallDesktop {
  .req-history-skeleton {
    &__single-request {
      &__content {
        justify-content: flex-start;
        &__frame {
          width: 164px;
          height: 164px;
        }
        &__description {
          padding-left: 16px;
          margin-right: 132px;
          width: 380px;

          &__title {
            height: 22px;
          }

          &__property {
            height: 18px;
          }
        }
      }
    }
  }
}
@media @desktop {
  .req-history-skeleton {
    &__single-request {
      width: 75%;
    }
  }
}

@media @bigDesktop {
  .req-history-skeleton {
    &__single-request {
      margin-bottom: 32px;
      width: 70%;
      &__content {
        &__frame {
          width: 200px;
          height: 200px;
        }
        &__description {
          padding-left: 16px;
          margin-right: 132px;

          &__title {
            height: 78px;
            margin-bottom: 16px;
          }
          &__property {
            height: 29px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}
