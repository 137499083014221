.review-overview {
  &__single-skeleton {
    margin-bottom: 24px;
    padding: 10px;
    -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.3);
    -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.3);
    box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.3);
    border-left: 4px solid rgba(112, 112, 112, 1);

    &__short {
      width: 10%;
      height: 14px;
    }

    &__super-short {
      width: 18px;
      height: 24px;
    }

    &__long {
      width: 100%;
      height: 18px;
    }
  }
}
