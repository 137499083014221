@keyframes shake {
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-20deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

@keyframes navi-from-left {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes navi-from-right {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@keyframes skeleton-load {
  0% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
