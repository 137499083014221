.contact-page {
  text-align: center;
  &__email-icon {
    font-size: 44px;
  }
  &__email {
    font-size: 20px;
    margin-bottom: 80px;
  }
}

@media @desktop {
  .contact-page {
    &__email-icon {
      font-size: 56px;
    }
    &__email {
      font-size: 44px;
    }
  }
}
