.ant-table-container {
  overflow-x: auto;
  font-size: 13px;
}

.ant-table-thead {
  th {
    background-color: @black !important;
    color: @white !important;
  }
}

@media @desktop {
  .ant-table-container {
    font-size: 14px;
  }
}
@media @bigDesktop {
  .ant-table-container {
    font-size: 16px;
  }
}
