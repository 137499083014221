.result-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  a {
    width: 100%;
  }
}

.single-result {
  cursor: pointer;
  border-top: 8px solid @gray;
  border-bottom: 4px solid @gray;
  -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  border-right: none;
  border-left: none;
  transition: 0.4s;
  width: 100%;
  margin-bottom: 24px;
  &:hover {
    border-color: @black;
    -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
    -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
    box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
    .single-result__content__frame__img {
      transform: scale(1.1);
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    &__frame {
      width: 80px;
      height: 80px;
      border: 1px solid @gray;
      padding: 8px;
      margin-bottom: 8px;
      &__img {
        width: 100%;
        height: 100%;
        background-color: @light-gray;
        transition: 0.4s;
      }
    }
    &__description {
      padding-left: 16px;
      width: calc(100% - 80px);
      &__title {
        font-size: 20px;
        padding-bottom: 4px;
        word-break: break-all;
      }
      &__property {
        font-size: 13px;
        font-weight: bold;
        &__label {
          color: @gray;
          padding-right: 4px;
          font-weight: normal;
        }
      }
    }
    &__rate {
      padding-left: 0px;
      margin-top: 8px;
      &__block {
        display: flex;
        justify-content: center;
        background-color: @light-gray;
        &__number {
          font-size: 32px;
        }
        &__star {
          font-size: 24px;
          color: @yellow;
          position: relative;
          top: 4px;
          padding-left: 4px;
          display: flex;
          align-items: center;
        }
      }
      &__votes {
        background-color: @black;
        background-color: @black;
        color: @white;

        padding: 2px;

        &__label {
        }
        &__number {
          font-weight: bold;
          padding-left: 2px;
        }
      }
    }
  }
}

.position {
  display: flex;
  justify-content: center;
  font-size: 32px;
  width: 100%;
  background-color: @gray;
  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(5) {
    color: @yellow;
  }
}

@media (min-width: 460px) {
  .single-result {
    &__content {
      justify-content: center;
    }
  }
}

@media (min-width: 555px) {
  .single-result {
    &__content {
      justify-content: space-around;
      &__frame {
        width: 124px;
        height: 124px;
      }
      &__description {
        padding-left: 0px;
        width: auto;
      }
      &__rate {
        padding-left: 0px;
      }
    }
  }
}

@media @smallDesktop {
  .single-result {
    &__content {
      justify-content: flex-start;
      &__frame {
        width: 164px;
        height: 164px;
      }
      &__description {
        padding-left: 16px;
        margin-right: 132px;
        width: auto;

        &__title {
          font-size: 28px;
          padding-bottom: 8px;
        }

        &__property {
          font-size: 14px;
          padding-bottom: 4px;
        }
      }
      &__rate {
        position: absolute;
        top: 24px;
        right: 24px;
        &__block {
          &__number {
            font-size: 40px;
          }
          &__star {
            font-size: 28px;
          }
        }
        &__votes {
          padding: 4px;
          &__number {
            padding-left: 4px;
          }
        }
      }
    }
  }
  .position {
    font-size: 48px;
  }
}
@media @desktop {
  .single-result {
    width: 75%;
  }
  .position {
    width: 24%;
    margin: 0 1% 0 0;
    font-size: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
  }
}

@media @bigDesktop {
  .position {
    width: 29%;
    margin: 0 1% 0 0;
  }
  .single-result {
    margin-bottom: 32px;
    width: 70%;
    &__content {
      &__frame {
        width: 200px;
        height: 200px;
      }
      &__description {
        padding-left: 16px;
        margin-right: 132px;

        &__title {
          font-size: 40px;
          padding-bottom: 16px;
        }
        &__property {
          font-size: 16px;
        }
      }
      &__rate {
        &__block {
          &__number {
            font-size: 64px;
          }
          &__star {
            font-size: 36px;
          }
        }
        &__votes {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}
