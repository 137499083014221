.ant-pagination-item {
  border: none;
  color: @gray;
  font-family: "Cantarell", sans-serif;
  transition: 0.4s;
  &:hover {
    a {
      color: @yellow;
    }
  }
  &:focus {
    a {
      color: @yellow;
    }
  }
}
.ant-pagination-item-active {
  background-color: @white;
  color: @yellow;
  border-bottom: 1px solid @black;
  border-top: 1px solid @black;
  a {
    font-weight: bold;
    color: @yellow;
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  span {
    transition: 0.4s;
  }
  &:hover {
    span {
      color: @yellow;
    }
  }
}
