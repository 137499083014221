.about-song-skeleton {
  &__title {
    width: 80%;
    height: 30px;
  }

  &__wrapper {
    &__info-block {
      &__info {
        display: flex;
        justify-content: center;
        .skeleton {
          width: 50%;
          height: 20px;
          margin-bottom: 4px;
        }

        .skeleton.shorter {
          width: 30%;
          height: 20px;
          margin-bottom: 4px;
        }
      }
      &__link {
        span {
          font-size: 24px;
          margin-left: 8px;
        }
      }
      &__rate {
        display: flex;
        justify-content: center;
        align-items: center;
        .skeleton {
          width: 50%;
          height: 20px;
          margin-bottom: 4px;
        }
        .anticon-star {
          font-size: 24px;
          margin-left: 8px;
          color: @yellow;
        }
      }
    }
  }
}
@media @bigMobile {
  .about-song-skeleton {
    &__title {
      width: 60%;
      height: 30px;
    }

    &__wrapper {
      &__info-block {
        &__info {
          .skeleton {
            width: 30%;
            height: 20px;
            margin-bottom: 4px;
          }

          .skeleton.shorter {
            width: 20%;
            height: 20px;
            margin-bottom: 4px;
          }
        }
        &__rate {
          .skeleton {
            width: 30%;
            height: 20px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

@media @desktop {
  .about-song-skeleton {
    &__title {
      width: 35%;
      height: 50px;
    }

    &__wrapper {
      display: flex;
      &__info-block {
        order: 2;
        padding-left: 24px;
        font-size: 16px;
        width: 550px;
        &__info {
          justify-content: flex-start;
        }
        &__rate {
          justify-content: flex-start;
        }
      }
    }

    &__frame {
      order: 1;
    }
  }
}
