// TILE SKELETON START
.recommended-article-tile-skeleton {
  height: auto;
  width: 100%;
  margin-bottom: 48px;
  color: @black;
  border: 1px solid @light-gray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;

  &__img-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    background: @light-gray;
  }

  &__text {
    width: 100%;
    padding: 8px 16px 16px 16px;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background: @white;
      position: absolute;
      top: -3px;
      left: 0;
    }

    &__title {
      width: 100%;
      height: 24px;
    }
    &__title-semi {
      width: 70%;
      height: 24px;
      margin-top: 8px;
    }
  }
}

@media @bigMobile {
  .recommended-article-tile-skeleton {
    height: auto;
  }
}
@media @smallDesktop {
  .recommended-article-tile-skeleton {
    width: 30%;
    margin-right: 3.33%;
    height: auto;

    &__img-container {
      height: 150px;
    }

    &__text {
      &__title {
        font-size: 20px;
      }
    }
  }
}

@media @bigDesktop {
  .recommended-article-tile-skeleton {
    width: 100%;
    margin-right: 0;
  }
}

// TILE SKELETON END

//RECOMEND TITLE START

.recomended-title-skeleton {
  width: 100%;
  height: 28px;
}

@media @smallDesktop {
  .recomended-title-skeleton {
    width: 30%;
  }
}

@media @bigDesktop {
  .recomended-title-skeleton {
    width: 100%;
  }
}

//RECOMEND TITLE END

//ARTICLE BODY START
.article-body-skeleton {
  width: 100%;
  padding: 0 24px;
  &__line {
    height: 20px;
    width: 100%;
    margin-top: 8px;
  }
  &__semi-line {
    width: 75%;
    height: 20px;
    margin-top: 8px;
  }
}

@media @smallDesktop {
  .article-body-skeleton {
    padding: 0 44px;
  }
}

//ARTICLE BODY END

//ARTICLE IMAGE START

.article-img-skeleton {
  width: 100%;
  height: 300px;
  background: @light-gray;
}

@media @bigMobile {
  .article-img-skeleton {
    height: 400px;
  }
}

@media @smallDesktop {
  .article-img-skeleton {
    height: 500px;
  }
}

@media @bigDesktop {
  .article-img-skeleton {
    height: 600px;
  }
}

// ARTICLE IMAGE END

//ARTICLE TITLE START
.article-title-skeleton {
  height: 50px;
  width: 70%;
  margin-top: 16px;
  margin-bottom: 58px;
}
//ARTICLE TITLE END
