.page-title {
  padding: 24px 32px;
  margin-bottom: 32px;
  border-left: 4px solid @gray;
  border-right: 4px solid @gray;

  font-size: 20px;
  letter-spacing: 1px;
}

@media @desktop {
  .page-title {
    font-size: 26px;
  }
}

@media @bigDesktop {
  .page-title {
    margin-bottom: 64px;
    font-size: 32px;
  }
}
