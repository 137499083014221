.burger {
  position: fixed;
  left: 0px;
  top: 64px;
  z-index: 101;
  padding: 10px 20px;
  height: 44px;
  border-radius: 0;
  border-right-color: @gray;

  svg {
    font-size: 22px;
  }
}

@media @bigMobile {
  .burger {
    top: 77px;
  }
}

.open-search {
  position: fixed;
  right: 24px;
  top: 32px;
  z-index: 100;
}

.navigation-mobile {
  position: fixed;
  left: 0;
  top: 108px;
  width: 100%;
  height: calc(100vh - 98px);
  z-index: 102;
  background: @white;
  border-right: 2px solid @light-gray;
  overflow-y: auto;
  animation-name: navi-from-left;
  animation-duration: 0.5s;

  &__close {
    font-size: 24px;
    float: right;
    padding: 16px;
    cursor: pointer;
  }

  &__account-block {
    border-top: 2px solid @light-gray;
    padding-top: 24px;
    width: 100%;
    &__nav-link {
      display: block;
      position: relative;
      top: 1px;
      vertical-align: bottom;
      color: @black;
      width: 100%;
      line-height: 46px;
      &:hover {
        border-right: 4px solid @black;
      }
    }
    &__user {
      padding: 0 24px 0 16px;
      a {
        color: @black;
      }
      &__img {
        width: 142px;
        height: 80px;
        display: block;
        margin: 0 auto 4px auto;
      }
      &__name {
        font-weight: bold;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 8px;
        text-align: center;
      }
      &__log-out {
        margin-top: 32px;
      }
    }
    &__skeleton-container {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      &__img {
        width: 80px;
        height: 80px;
        margin-left: 24px;
        border-radius: 50%;
        margin-bottom: 8px;
      }
      &__nickname {
        width: 150px;
        height: 24px;
      }
    }
  }
  &__search {
    position: fixed;
    right: 0;
    top: 64px;
    z-index: 100;
    width: 100%;
    background: @black;
    .ant-form {
      width: calc(100% - 64px);
      margin-left: 64px;
    }
    .input-container {
      svg:hover {
        color: @white;
      }
    }
    input {
      border-radius: 0;
      background: @black;
      color: @white;
      border: none;
      box-shadow: none;
    }
  }
  &__menu {
    background: inherit;
    .ant-menu {
      background: inherit;
    }
    li {
      text-align: center;
    }
  }

  &__manage-account {
    display: flex !important;
  }

  .ant-menu-item {
    border-color: @light-gray;
    &:hover {
      border-right: 4px solid @black;
    }
    &:focus {
      border-color: @light-gray;
    }
    &:active {
      border-color: @light-gray;
    }
  }

  .ant-menu-item-selected {
    border-color: @black;
    font-weight: bold;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  position: relative;
  width: 100%;

  &__account-block {
    display: flex;
    width: 35%;
    height: 100%;
    &__devider {
      line-height: 46px;
    }
    &__nav-link {
      position: relative;
      top: 1px;
      vertical-align: bottom;
      color: @black;
      height: 100%;
      line-height: 45px;
      border-bottom: 1px solid @light-gray;

      &:hover {
        line-height: 44px;
        border-bottom: 2px solid @black;
      }
    }
    &__user {
      padding: 0 24px 0 16px;
      width: 100%;
      position: relative;
      &:hover {
        .navigation__account-block__user__on-hover {
          display: block;
        }
      }
      &__visible {
        display: flex;
        align-items: flex-end;
        color: @black;

        &__img {
          width: 72px;
          height: 40px;
          margin-bottom: 6px;
        }
        &__name {
          width: calc(100% - 40px);
          font-weight: bold;
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-left: 8px;
          margin-bottom: 8px;
          span {
            margin-left: 16px;
            position: relative;
            top: 4px;
          }
        }
      }
      &__on-hover {
        display: none;
        position: absolute;
        border-top: 2px solid @black;
        top: 100%;
        z-index: 10;
        color: @black;
        background: @white;
        width: calc(100% - 16px);
        padding: 24px;

        &__log-out {
          margin-top: 32px;
        }
      }
    }
    &__skeleton-container {
      display: flex;
      align-items: center;
      width: 100%;
      &__img {
        width: 40px;
        height: 40px;
        margin-right: 4px;
        margin-left: 8px;
      }
      &__nickname {
        width: 40%;
        height: 26px;
        margin-right: 4px;
      }
    }
  }
  &__search {
    width: 15%;
    transition: 0.5s ease all;
    -moz-transition: 0.5s ease all;
    -webkit-transition: 0.5s ease all;
    margin-right: 5px;
  }
  &__search.focused {
    width: 30%;
  }
  &__menu {
    width: 65% !important;
  }
  &__manage-account {
    display: flex !important;
  }

  .ant-menu {
    display: flex;
    justify-content: flex-end;
  }

  .ant-menu-item {
    border-color: @light-gray;
    &:focus {
      border-color: @light-gray;
    }
    &:active {
      border-color: @light-gray;
    }
  }

  .ant-menu-item-selected {
    border-color: @black;
    font-weight: bold;
  }

  .longer-border-bottom {
    width: 100%;
    height: 2px;
    content: "";
    background-color: @light-gray;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}

.navigation-active {
  border-bottom: 2px solid @black;
  font-weight: bold;
}

.navigation-mobile-active {
  border-right: 4px solid @black;
  font-weight: bold;
  background-color: @light-gray;
}

.ant-menu:not(.ant-menu-inline)
  .ant-menu:not(.ant-menu-item-only-child)
  .ant-menu-item-selected {
  background-color: @light-gray !important;
  border-left: 2px solid @black;
  border-right: 2px solid @black;
  font-weight: bold;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @light-gray !important;
  border-right: 2px solid @black;
  font-weight: bold;
}

@media @bigMobile {
  .navigation-mobile {
    top: 121px;
    &__search {
      top: 77px;
    }
  }
}

@media (min-width: 950px) {
  .navigation-pc-paddings {
    padding: 0 50px;
  }
}

@media (min-width: 1400px) {
  .navigation-pc-paddings {
    max-width: 1260px;
    padding: 0 0;
    margin: 0 auto;
  }
}
