.overall-block {
  width: 100%;
  &__label {
    color: @gray;
    padding-right: 4px;
    font-size: 16px;
  }
  &__number {
    font-size: 24px;
    margin-left: 8px;
  }

  svg {
    color: @yellow;
    font-size: 24px;
    margin-left: 8px;
    position: relative;
    top: 2px;
  }
}

@media @desktop {
  .overall-block {
    width: 100%;
    &__label {
      color: @gray;
      padding-right: 4px;
      font-size: 18px;
    }
    &__number {
      font-size: 32px;
      margin-left: 8px;
    }

    svg {
      font-size: 32px;
    }
  }
}
