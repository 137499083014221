.footer {
  margin-top: 40px;
  padding-top: 16px;
  border-top: 2px solid @light-gray;
  &__expand {
    color: @gray;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 16px;
    cursor: pointer;
    svg {
      position: relative;
      top: 3px;
      font-size: 16px;
      padding-left: 3px;
    }
  }
  &__links {
    margin-bottom: 16px;
    &__block {
      display: flex;
      flex-direction: column;
      text-align: center;
      a {
        color: @gray;
        margin: 8px 0;
        font-weight: bold;
        &:hover {
          color: @black;
        }
      }
    }
  }
  &__social-media {
    padding: 16px 0;
    width: 100%;
    background-color: @black;
    display: flex;
    justify-content: center;
    svg {
      margin: 0 16px;
      color: @white;
      font-size: 24px;
      transition: 0.4s;
      &:hover {
        color: @yellow;
      }
    }
  }
  &__copyright {
    background-color: @black;
    color: @light-gray;
    text-align: center;
    padding: 4px 0;
  }
}

@media @bigMobile {
  .footer {
    margin-top: 64px;
    padding-top: 24px;
    border-top: 2px solid @light-gray;
    &__links {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      padding: 0 10%;
      &__block {
        display: flex;
        flex-direction: column;
        text-align: start;

        a {
          color: @gray;
          margin: 8px 0;
          font-weight: bold;
          &:hover {
            color: @black;
          }
        }
      }
    }
  }
}
