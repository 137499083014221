.register-page {
  &__register {
    width: 100%;
    margin: 0 auto;
    &__submit {
      width: 100%;
    }
  }
  &__login-block {
    text-align: center;
    margin-top: 16px;
    a {
      margin-left: 4px;
    }
  }
}

@media @bigMobile {
  .register-page {
    &__register {
      width: 90%;
      &__submit {
        width: 90%;
      }
    }
  }
}

@media @smallDesktop {
  .register-page {
    &__register {
      width: 50%;
      &__submit {
        width: 60%;
      }
    }
  }
}

.registration-advantage-baner {
  display: flex;
  flex-wrap: wrap;
  padding-top: 64px;
  &__item {
    width: 50%;
    text-align: center;
    padding: 0 20px;
    .anticon {
      font-size: 40px;
      color: @yellow;
    }
    &:nth-child(3) {
      padding: 16px 33.33% 0 33.33%;
      width: 100%;
    }
  }
}

@media @smallDesktop {
  .registration-advantage-baner {
    padding-top: 64px;
    &__item {
      width: 33.33%;
      .anticon {
        font-size: 60px;
      }
      &:nth-child(3) {
        padding: 0 20px;
        width: 33.33%;
      }
    }
  }
}
