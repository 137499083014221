.like {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &__number {
    font-size: 16px;
    font-weight: bold;
    padding-left: 4px;
  }
}
