.ant-carousel .slick-dots li {
  background-color: @black;
}

.ant-carousel .slick-dots li.slick-active {
  background-color: @yellow;
}

.ant-carousel .slick-dots li.slick-active button {
  background: transparent;
}

.ant-carousel .slick-prev::before {
  content: "<";
  color: @black;
  width: 20px;
  height: 20px;
  display: block;
  font-weight: bold;
  font-size: 20px;
  transition: 0.4s;
}
.ant-carousel .slick-prev:hover {
  &:before {
    color: @yellow;
  }
}

.ant-carousel .slick-next::before {
  content: ">";
  color: @black;
  width: 20px;
  height: 20px;
  display: block;
  font-weight: bold;
  font-size: 20px;
  transition: 0.4s;
}

.ant-carousel .slick-next:hover {
  &:before {
    color: @yellow;
  }
}
