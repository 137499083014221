.rate-stars-block {
  &__label {
    text-align: center;
  }
  &__value {
    font-size: 24px;
  }
  &__value--inactive {
    font-size: 24px;
    color: @white;
  }
}
