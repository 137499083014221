// This set of mixins allows you to create margin and padding classes dynamically.

// In html, you would specify <div class="mr20-xs">Content</div> to give you margin-right: 20px
// It is based on the responsive capabilities of Bootstrap. <div class="mr20-sm"> would give you
// 20 pixels of right margin on screens small and larger.

// Mixin control

// .make-margins(@breakpoint, @size, @decrement)
//        @breakpoint: To be used with Bootstrap. Must supply breakpoints: xs, sm, md or lg
//        @size: Largest size of margin you desire. Default is 50
//        @decrement: Amount @size is decremented on each loop. Default is 10

// .make-paddings uses the same parameters

// MARGIN

.make-margins(@multply-value, @size: 20, @decrement: 1) when (@size >= 0) {
  .make-margins(@multply-value, @size - @decrement);

  @size-multiplied: @size* @multply-value;
  @size-px: ~"@{size-multiplied}px";

  .m-@{size} {
    margin: @size-px;
  }
  .mt-@{size} {
    margin-top: @size-px;
  }
  .mr-@{size} {
    margin-right: @size-px;
  }
  .mb-@{size} {
    margin-bottom: @size-px;
  }
  .ml-@{size} {
    margin-left: @size-px;
  }
}
// PADDING

.make-paddings(@multply-value, @size: 20, @decrement: 1) when (@size >= 0) {
  .make-paddings(@multply-value, @size - @decrement);

  @size-multiplied: @size* @multply-value;
  @size-px: ~"@{size-multiplied}px";

  .p-@{size}{
    padding: @size-px;
  }
  .pt-@{size} {
    padding-top: @size-px;
  }
  .pr-@{size} {
    padding-right: @size-px;
  }
  .pb-@{size} {
    padding-bottom: @size-px;
  }
  .pl-@{size} {
    padding-left: @size-px;
  }
}

.make-margins(4);
.make-paddings(4);

@media (min-width: 1100px) {
  .make-margins(8);
  .make-paddings(8);
}
