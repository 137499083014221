.bo__song-req {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 4px solid @gray;
    &__important-value {
      font-weight: 400;
      font-size: 16px;
    }
    &__buttons {
      display: flex;
      justify-content: center;
      &__green {
        background: green;
        margin: 20px;
        cursor: pointer;
      }
      &__red {
        background: red;
        margin: 20px;
        cursor: pointer;
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__textarea {
        width: 300px;
        height: 100px;
        margin-bottom: 20px;
      }
    }
  }
}
