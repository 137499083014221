//ARTICLE GRID START

.article-grid {
  display: flex;
  flex-direction: column;
  &__left {
    width: 100%;
  }
  &__right {
    width: 100%;
  }
}

@media @bigDesktop {
  .article-grid {
    flex-direction: row;
    &__left {
      width: 80%;
    }
    &__right {
      width: 20%;
      padding-left: 44px;
    }
  }
}

//ARTICLE GRID END

// TITLE START
.article-title {
  font-size: 24px;
  font-family: "Abril Fatface", cursive;
  letter-spacing: 0.8px;
  padding-top: 8px;
}

@media @bigMobile {
  .article-title {
    font-size: 28px;
  }
}

@media @smallDesktop {
  .article-title {
    font-size: 40px;
    padding-top: 16px;
  }
}

@media @bigDesktop {
  .article-title {
    font-size: 48px;
    line-height: 60px;
  }
}
// TITLE END

//TIME STAMP START
.article-time-stamp {
  font-size: 18px;
  padding: 4px 6px;
  background: @black;
  color: @white;
  border-radius: 5px;
  margin-bottom: 16px;
}
@media @smallDesktop {
  .article-time-stamp {
    font-size: 20x;
    margin-bottom: 32px;
  }
}
//TIME STAMP END

//HEADER IMAGE START
.article-img {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    object-fit: cover;
    object-position: center;
  }
  &__tags-block {
    display: none;
    position: absolute;
    left: 12px;
    bottom: 12px;
    &__tag {
      background: @white;
      font-weight: 400;
      font-size: 10px;
      padding: 4px;
      border-radius: 5px;
      margin-right: 4px;
    }
  }
}

@media @smallDesktop {
  .article-img {
    &__tags-block {
      display: block;
    }
  }
}

//HEADER IMAGE END

//BODY START
.article-body {
  margin-top: 16px;
  font-size: 16px;

  &__sheet {
    display: flex;
    &__rate-col {
      width: 0;
      position: relative;
    }

    &__content {
      width: 100%;
      padding: 0 24px;
      &__teaser {
        margin-bottom: 24px;
        letter-spacing: 0.5px;
      }

      &__external-html p,
      &__external-html li,
      &__external-html h1,
      &__external-html h2,
      &__external-html h3,
      &__external-html span,
      &__external-html strong,
      &__external-html i {
        font-family: "Mulish", sans-serif !important;
        letter-spacing: 0.5px;
      }

      &__external-html iframe {
        width: 100%;
      }

      &__external-html img {
        max-width: 100%;
        object-fit: cover;
        object-position: center;
      }

      &__author {
        margin-top: 24px;
        font-weight: 700;
        text-align: center;
        svg {
          font-weight: 700;
          font-size: 18px;
        }
      }
      &__tags {
        font-size: 14px;
        h3 {
          display: inline;
        }
      }
    }
  }
}

@media @smallDesktop {
  .article-body {
    margin-top: 44px;
    &__sheet {
      &__rate-col {
        width: 25%;
      }
      &__content {
        padding: 0 44px;
        &__author {
          margin-top: 24px;
        }
      }
    }
  }
}
//BODY END

//RECOMMEND MONTH SECTION START
.recommend-month-section {
  margin-top: 50px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media @smallDesktop {
  .recommend-month-section {
    padding-left: 20%;
    flex-direction: row;
  }
}

@media @bigDesktop {
  .recommend-month-section {
    padding-left: 0;
    flex-direction: column;
  }
}
//RECOMMEND MONTH SECTION END

//ARTICLE RECOMENDATION SECTION START
.article-recommendation-section {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media @smallDesktop {
  .article-recommendation-section {
    padding-left: 20%;
    flex-direction: row;
  }
}

@media @bigDesktop {
  .article-recommendation-section {
    padding-left: 0;
    flex-direction: column;
  }
}

//ARTICLE RECOMMENDATION SECTION END

//RATE BLOCK START
.rate-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &__coppy {
    display: none;
  }

  &__star-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    svg {
      margin-top: 8px;
      font-size: 24px;
      color: @yellow;
    }
  }
}

@media @smallDesktop {
  .rate-block {
    margin-top: 0px;
    position: sticky;
    top: 100px;

    &__coppy {
      display: flex;
      justify-content: center;
      span {
        border-radius: 50%;
        padding: 8px;
        background: @light-gray;
        svg {
          font-size: 24px;
          &:hover {
            color: @yellow;
          }
        }
      }
    }

    &__star-part {
      margin-top: 44px;
    }
  }
}

//RATE BLOCK END

// ARTICLE COMMENT WRAPPER START
.article-comment-wrapper {
  margin: 32px auto;
  width: 100%;
}

@media @desktop {
  .article-comment-wrapper {
    margin: 44px auto;
    width: 80%;
  }
}

@media @bigDesktop {
  .article-comment-wrapper {
    margin: 88px auto;
    width: 60%;
  }
}
// ARTICLE COMMENT WRAPPER END
