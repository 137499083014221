// MY ACCOUNT BIO START
.profile {
  &__nickname {
    font-size: 32px;
    margin-bottom: 24px;
    font-weight: bold;
    &__edit {
      font-size: 18px;
      color: @gray;
      transition: 0.4s;
      &:hover {
        color: @black;
      }
    }
  }
  &__main-block {
    display: flex;
    flex-direction: column;
    &__img-container {
      width: 100%;
      img {
        width: 100%;
      }
    }
    &__content {
      width: 100%;
      &__item {
        margin-bottom: 16px;
        &__label {
          color: @gray;
          margin-right: 4px;
        }
      }
      &__description-block {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        &__header {
          font-size: 20px;
        }
        &__text {
          padding-top: 6px;
          margin-left: 12px;
          font-size: 16px;
        }
      }
    }
  }
}

@media @smallDesktop {
  .profile {
    &__main-block {
      flex-direction: row;

      &__img-container {
        width: 30%;
      }

      &__content {
        width: 70%;
        margin-left: 32px;
        &__description-block {
          flex-direction: row;
          &__header {
            font-size: 24px;
          }
          &__text {
            padding-top: 12px;
            margin-left: 24px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

// MY ACCOUNT BIO END

//REQUEST HISTORY
.req-history {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  &__single-request {
    border-top: 8px solid @gray;
    border-bottom: 4px solid @gray;
    -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
    -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
    box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
    border-right: none;
    border-left: none;
    transition: 0.4s;
    width: 100%;
    margin-bottom: 24px;
    &:hover {
      border-color: @black;
      -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
      -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
      box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
      .single-request__content__frame__img {
        transform: scale(1.1);
      }
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      &__frame {
        width: 80px;
        height: 80px;
        border: 1px solid @gray;
        padding: 8px;
        margin-bottom: 8px;
        &__icon {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
            color: @red;
          }
        }
        &__img {
          width: 100%;
          height: 100%;
          background-color: @light-gray;
          transition: 0.4s;
        }
      }
      &__description {
        padding-left: 16px;
        width: calc(100% - 80px);
        &__title {
          font-size: 20px;
        }
        &__property {
          font-size: 13px;
          font-weight: bold;
          &__label {
            color: @gray;
            padding-right: 4px;
            font-weight: normal;
          }
        }
      }
      &__state-block {
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        padding-bottom: 12px;
        display: flex;
        justify-content: center;
        margin-top: 12px;
        svg {
          font-size: 26px;
          padding-left: 8px;
          position: relative;
          top: 8px;
        }
      }
      &__state-block.red {
        color: @red;
        background: fade(@red, 20%);
      }
      &__state-block.green {
        color: @green;
        background: fade(@green, 20%);
      }
      &__state-block.orange {
        color: @orange;
        background: fade(@orange, 20%);
      }
    }
  }
}

@media (min-width: 460px) {
  .req-history {
    &__single-request {
      &__content {
        justify-content: center;
      }
    }
  }
}

@media (min-width: 555px) {
  .req-history {
    &__single-request {
      &__content {
        justify-content: space-around;
        &__frame {
          width: 124px;
          height: 124px;
        }
        &__description {
          padding-left: 0px;
          width: auto;
        }
        &__state-block {
          font-size: 18px;
          svg {
            font-size: 32px;
            padding-left: 8px;
            top: 10px;
          }
        }
      }
    }
  }
}

@media @smallDesktop {
  .req-history {
    &__single-request {
      &__content {
        justify-content: flex-start;
        &__frame {
          width: 164px;
          height: 164px;
        }
        &__description {
          padding-left: 16px;
          margin-right: 132px;
          width: auto;

          &__title {
            font-size: 28px;
          }

          &__property {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media @desktop {
  .req-history {
    &__single-request {
      width: 75%;
    }
  }
}

@media @bigDesktop {
  .req-history {
    &__single-request {
      margin-bottom: 32px;
      width: 70%;
      &__content {
        &__frame {
          width: 200px;
          height: 200px;
        }
        &__description {
          padding-left: 16px;
          margin-right: 132px;

          &__title {
            font-size: 40px;
            padding-bottom: 16px;
          }
          &__property {
            padding-bottom: 4px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

//REQUEST HISTORY END

//MY ACCOUNT RATES (START)
.rate-tabs {
  .ant-card-body {
    width: 100%;
  }
  &__single {
    display: flex;
    flex-wrap: wrap;
    &__left {
      width: 100%;
      &__title {
        color: @yellow;
        text-align: center;
        margin-bottom: 16px;
        word-break: break-all;
      }
    }

    &__right {
      color: @black;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &__rate--normal {
        width: 33.33%;
        text-align: center;
        &__label {
          font-size: 16px;
          color: @gray;
        }
        &__value {
          font-size: 20px;
        }
      }

      &__rate--main {
        width: 100%;
        text-align: center;
        margin-top: 24px;

        &__label {
          font-size: 18px;
          font-weight: 700;
        }
        &__value {
          font-size: 24px;

          svg {
            position: relative;
            top: 2px;
            left: 1px;
            color: @yellow;
          }
        }
      }
    }
  }
}

@media @bigMobile {
  .rate-tabs {
    .ant-card-body {
      width: 100%;
    }
    &__single {
      &__right {
        &__rate--normal {
          &__label {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media @bigDesktop {
  .rate-tabs {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

//MY ACCOUNT RATES (END)

// AWARDS (START)
.awards-bio {
  border-top: 1px solid @gray;
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-size: 32px;
    margin-bottom: 64px;
  }

  &__list {
    &__item {
      display: flex;
      font-weight: 700;
      align-items: center;
      margin-bottom: 44px;

      &__img-container {
        margin-left: 12px;

        img {
          width: 100%;
        }
      }
    }
  }
}
//AWARDS (END)
