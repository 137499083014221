.leagues-progress {
  .ant-progress-bg {
    background-color: @yellow;
  }
  &__league {
    strong {
      padding-left: 4px;
    }
  }
}
