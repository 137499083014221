.ant-notification-notice-content {
  cursor: pointer;
}

.ant-notification-notice-message {
  text-transform: uppercase;
  font-size: 18px;
  color: @yellow;
  font-weight: 700;
}

.ant-notification-notice-description {
  color: @gray;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.ant-notification-notice-icon svg {
  color: @gray;
}
