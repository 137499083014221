.ant-btn {
  border: 1px solid @black;
  color: @white;

  background: linear-gradient(to left, @white 50%, @black 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.4s ease-out;

  &:hover {
    background: linear-gradient(to left, @white 50%, @black 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: @black;
  }
}
