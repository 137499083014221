.article-tile {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  height: 460px;
  margin-bottom: 48px;
  cursor: pointer;
  color: @black;
  border: 1px solid @light-gray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;

  &:hover {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  &:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  &__img-container {
    width: 100%;
    height: 40%;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &__date {
      position: absolute;
      bottom: 12px;
      left: 9px;
      background: @white;
      padding: 0 2px;
      font-weight: 700;
      border-radius: 5px;
    }
  }

  &__text {
    width: 100%;
    padding: 8px 16px 16px 16px;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background: @white;
      position: absolute;
      top: -3px;
      left: 0;
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__teaser {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__tags {
    position: absolute;
    right: auto;
    left: 16px;
    bottom: 12px;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    height: 22px;
    overflow: hidden;
    &__single-tag {
      color: @white;
      background: @black;
      border-radius: 5px;
      padding: 2px 4px;
      margin: 0 3px;
      font-weight: 400;
    }
  }
}

@media @bigMobile {
  .article-tile {
    height: 440px;
  }
}
@media @smallDesktop {
  .article-tile {
    width: 80%;
    max-width: 840px;
    margin-left: 4%;
    height: 440px;

    &__img-container {
      height: 50%;
    }

    &__text {
      padding: 12px 24px 24px 24px;

      &__title {
        font-size: 22px;
        margin-bottom: 12px;
        -webkit-line-clamp: 2;
      }
      &__teaser {
        font-size: 16px;
        -webkit-line-clamp: 3;
      }
    }

    &__tags {
      right: 24px;
      left: auto;
      bottom: 12px;
      height: auto;
    }
  }
}
