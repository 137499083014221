.logo-baner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
  img {
    width: 220px;
  }
}

@media @bigMobile {
  .logo-baner {
    margin-bottom: 84px;
    img {
      width: 260px;
    }
  }
}

@media @smallDesktop {
  .logo-baner {
    margin-bottom: 16px;
    img {
      width: 350px;
    }
  }
}

@media @bigDesktop {
  .logo-baner {
    img {
      width: 464px;
    }
  }
}
