.cover-photo {
  &__frame {
    width: 250px;
    height: 250px;
    border: 1px solid @gray;
    padding: 10px;
    &__img {
      width: 100%;
      height: 100%;
      background-color: @light-gray;
    }
  }

  &__frame.skeleton {
    background-color: @light-gray;
    border-radius: 0;
  }
}

@media @bigMobile {
  .cover-photo {
    &__frame {
      width: 300px;
      height: 300px;
    }
  }
}
