.rate-tabs__single-skeleton {
  &__left {
    width: 100%;
    &__title {
      margin: 0 auto 16px auto;
      width: 30%;
      height: 26px;
    }
  }

  &__right {
    color: @black;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__rate--normal {
      width: 33.33%;
      text-align: center;
      &__label {
        margin: 0 auto;
        width: 50%;
        height: 20px;
      }
      &__value {
        margin: 0 auto;
        width: 18px;
        height: 22px;
        display: block;
        margin-top: 3px;
      }
    }

    &__rate--main {
      width: 100%;
      text-align: center;
      margin-top: 24px;

      &__label {
        margin: 0 auto;
        width: 15%;
        height: 20px;
      }
      &__value {
        font-size: 24px;
        &__number {
          margin: 0 auto;
          width: 18px;
          height: 26px;
          display: inline-block;
          margin-top: 3px;
        }

        svg {
          position: relative;
          top: 2px;
          left: 1px;
          color: @yellow;
        }
      }
    }
  }
}

@media @bigMobile {
  .rate-tabs__single-skeleton {
    &__right {
      &__rate--normal {
        &__label {
          font-size: 18px;
        }
      }
    }
  }
}
