.bo__add-article {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ant-row {
      margin-bottom: 32px;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title-input {
      font-weight: 700;
      font-size: 16px;
    }

    &__radio-group {
      margin-bottom: 32px;
    }

    &__jodit-editor-container {
      width: 100%;
    }

    &__display-wrapper {
      width: 80%;
    }
  }
}

.recomended-selecetor {
  border: 1px solid @light-gray;
  padding: 24px 32px;
  height: 450px;
  width: 100%;
  overflow-y: scroll;
  &__tile {
    display: flex;
    width: 100%;
    height: 400px;
    margin: 32px 0;
    &__left {
      width: 70%;
      margin-right: 10%;
      cursor: pointer;
      border: 1px solid @light-gray;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      position: relative;
      &:hover {
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
      }
      &:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      &__img-container {
        width: 100%;
        height: 50%;
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
          transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        &__date {
          position: absolute;
          bottom: 12px;
          left: 9px;
          background: @white;
          padding: 0 2px;
          font-weight: 700;
          border-radius: 5px;
        }
      }

      &__text {
        width: 100%;
        padding: 24px;
        position: relative;
        &::before {
          display: block;
          content: "";
          width: 100%;
          height: 3px;
          background: @white;
          position: absolute;
          top: -3px;
          left: 0;
        }
        &__teaser {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__tags {
        position: absolute;
        right: 24px;
        bottom: 12px;
        font-size: 12px;
        &__single-tag {
          color: @white;
          background: @gray;
          border-radius: 5px;
          padding: 2px;
          margin-right: 3px;
          font-weight: 400;
        }
      }
    }
    &__right {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      &__selected-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        font-size: 32px;
        background: @gray;
        color: @white;
        border-radius: 50%;
        position: relative;
        bottom: 3px;
      }
    }
  }

  &__show-more {
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
  }
}

.recomended-selecetor.error {
  border: 4px solid @red;
}
