.recommended-article-tile {
  height: auto;
  width: 100%;
  margin-bottom: 48px;
  cursor: pointer;
  color: @black;
  border: 1px solid @light-gray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;

  &:hover {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: @black;
  }
  &:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  &__img-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &__date {
      position: absolute;
      bottom: 12px;
      left: 9px;
      background: @white;
      padding: 0 2px;
      font-weight: 700;
      border-radius: 5px;
    }
  }

  &__text {
    width: 100%;
    padding: 8px 16px 16px 16px;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background: @white;
      position: absolute;
      top: -3px;
      left: 0;
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

@media @bigMobile {
  .recommended-article-tile {
    height: auto;
  }
}
@media @smallDesktop {
  .recommended-article-tile {
    width: 30%;
    margin-right: 3.33%;
    height: auto;

    &__img-container {
      height: 150px;
    }

    &__text {
      &__title {
        font-size: 20px;
      }
    }
  }
}

@media @bigDesktop {
  .recommended-article-tile {
    width: 80%;
    margin-right: 0;
  }
}
