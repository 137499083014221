.ant-radio-button-wrapper {
  width: 46%;
  margin: 2%;
  text-align: center;
  color: @gray;
}
.ant-radio-button-wrapper-checked {
  background: @yellow !important;
}

@media @smallDesktop {
  .ant-radio-button-wrapper {
    margin: auto;
    width: auto;
    margin: 8px 4px;
  }
}
