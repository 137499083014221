.cookie-consent-bg {
  background: rgba(112, 112, 112, 0.9);
  z-index: 100;
  padding: 24px 0px 10px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.cookie-consent {
  display: flex;
  flex-wrap: wrap;
  color: @white;

  &__text {
    width: 100%;
    &__link {
      margin-top: 4px;
    }
  }
  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      position: relative;
      bottom: 14px;
    }
  }
}

@media @desktop {
  .cookie-consent {
    padding: 32px 0;

    &__text {
      width: 90%;
      display: flex;
      &__link {
        margin-top: 0;
        margin-left: 12px;
        width: 30%;
      }
    }
    &__btn-container {
      align-items: flex-end;
      width: 10%;
      button {
        bottom: 0;
      }
    }
  }
}

@media (min-width: 1220px) {
  .cookie-consent {
    padding: 44px 0;

    &__text {
      width: 95%;
      &__link {
        margin-left: 24px;
        width: 20%;
      }
    }
    &__btn-container {
      width: 5%;
    }
  }
}
