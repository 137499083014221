.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.w100 {
  width: 100%;
}

//colors
.c-black {
  color: @black;
}

.c-gray {
  color: @gray;
}

.c-red {
  color: @red;
}

.c-yellow {
  color: @yellow;
}

// backgrounds
.bg-light-gray {
  background-color: @light-gray;
}

//borders
.border-b-yellow {
  border-bottom: 1px dashed @yellow;
}

.text-justify {
  text-align: justify;
}

.skeleton {
  content: "";
  background-color: @light-gray;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    content: "";
    background: linear-gradient(0.25turn, transparent, #fff, transparent);
    pointer-events: none;
    opacity: 0.5;
    animation: skeleton-load 2s infinite;
  }
}

.highlight {
  padding: 0;
  color: @yellow;
  background-color: transparent;
}
