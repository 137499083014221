.no-results-serach {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 44px;
  &__header {
    font-weight: 700;
    font-size: 20px;
    color: @gray;
    text-align: center;
  }

  &__icon {
    font-size: 64px;
    padding: 24px 0;
  }
}

@media @smallDesktop {
  .no-results-serach {
    margin-bottom: 64px;
    &__header {
      font-weight: 700;
      font-size: 26px;
      color: @gray;
      text-align: center;
    }

    &__icon {
      font-size: 64px;
      padding: 32px 0;
    }
  }
}
