.single-artist-result-skeleton {
  cursor: pointer;
  border-top: 8px solid @gray;
  border-bottom: 4px solid @gray;
  -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  border-right: none;
  border-left: none;
  transition: 0.4s;
  width: 100%;
  margin-bottom: 24px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    &__frame {
      width: 80px;
      height: 80px;
      border: 1px solid @gray;
      padding: 8px;
      margin-bottom: 8px;
      background-color: @light-gray;
    }
    &__title {
      display: flex;
      width: calc(100% - 80px);
      justify-content: center;
      align-items: center;
      padding-left: 16px;
      .skeleton {
        width: 60%;
        height: 30px;
      }
    }
  }
}

@media (min-width: 460px) {
  .single-artist-result-skeleton {
    &__content {
      justify-content: center;
    }
  }
}

@media (min-width: 555px) {
  .single-artist-result-skeleton {
    &__content {
      &__frame {
        width: 124px;
        height: 124px;
      }
      &__title {
        width: calc(100% - 124px);
      }
    }
  }
}

@media @smallDesktop {
  .single-artist-result-skeleton {
    &__content {
      justify-content: flex-start;
      &__frame {
        width: 164px;
        height: 164px;
      }
      &__title {
        width: calc(100% - 164px);
        .skeleton {
          width: 50%;
          height: 50px;
        }
      }
    }
  }
}
@media @desktop {
  .single-artist-result-skeleton {
    width: 75%;
  }
}

@media @bigDesktop {
  .single-artist-result-skeleton {
    margin-bottom: 32px;
    width: 70%;
    &__content {
      &__frame {
        width: 200px;
        height: 200px;
      }
      &__title {
        font-size: 42px;
        width: calc(100% - 200px);
      }
    }
  }
}
