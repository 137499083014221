.home-page {
  h2 {
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    padding-bottom: 16px;
  }
  &__count {
    padding: 32px 0 32px 0;
    margin-top: 24px;
    margin-bottom: 32px;
    border: 3px solid @gray;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/hp-white-bg.jpg?alt=media");
    background-size: cover;
    &__text {
      font-size: 30px;
      text-align: center;
      margin: 0 8px 24px 8px;
      .number {
        width: 100%;
        padding-right: 8px;
        text-align: center;
        display: block;
        color: @yellow;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
@media @smallDesktop {
  .home-page {
    &__count {
      padding: 84px 0 84px 0;
      margin-top: 44px;
      margin-bottom: 44px;
      &__text {
        font-size: 44px;

        .number {
          display: inline;
          font-size: 64px;
        }
      }
      &__buttons {
        flex-direction: row;

        .btn {
          margin: 0 12px;
        }
      }
    }
  }
}

@media @bigDesktop {
  .home-page {
    &__count {
      &__text {
        font-size: 52px;
        .number {
          font-size: 72px;
        }
      }
    }
  }
}

// CAROUSEL START //
.home-carousel {
  position: relative;
  bottom: 40px;
  &__item {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 15%;
    right: 15%;
    width: 70%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      transparent,
      rgb(48, 49, 51),
      transparent
    );
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -25px;
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
    background-color: @gray;
    border: 1px solid @black;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px white, 0 0 0 4px white;
  }
}

// CAROUSEL END //

// CAROUSEL RECENTLY ADDED START //
.recently-added-header {
  margin-top: 8px;
  text-align: center;
}
.recently-added-carousel {
  &__item {
    display: flex !important;
    width: 100%;
    margin: 0 0 44px 0;

    .ant-card-bordered {
      height: 100%;
    }

    &__tile {
      display: block;
      position: relative;
      cursor: pointer;
      border-top: 8px solid @gray;
      border-bottom: 4px solid @gray;
      border-right: none;
      border-left: none;

      transition: 0.4s;
      width: 80%;
      margin: 0 auto;

      &:hover {
        border-color: @black;
        -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
        -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
        box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.4);
        .recently-added-carousel__item__tile__content__frame__img {
          transform: scale(1.1);
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        &__frame {
          width: 120px;
          height: 120px;
          border: 1px solid @gray;
          padding: 8px;
          margin-bottom: 8px;
          &__img {
            width: 100%;
            height: 100%;
            background-color: @light-gray;
            transition: 0.4s;
          }
          &__img-skeleton {
            width: 100%;
            height: 100%;
            border-radius: 0 !important;
          }
        }
        &__description {
          padding: 0 8px;
          text-align: center;
          margin-bottom: 80px;
          &__title {
            font-size: 20px;
            margin-bottom: 24px;
          }
          &__title-skeleton {
            margin-bottom: 30px;
            margin-top: 4px;
            height: 26px;
            width: 200px;
          }
          &__property {
            font-size: 13px;
            font-weight: bold;
            &__label {
              color: @gray;
              padding-right: 4px;
              font-weight: normal;
            }
            &__label-skeleton {
              width: 100px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 12px;
              height: 18px;
            }
          }
        }
        .btn {
          position: absolute;
          bottom: 24px;
          svg {
            font-size: 18px;
            position: relative;
            top: 2px;
            color: @yellow;
          }
        }
      }
    }
  }
}
@media @bigMobile {
  .recently-added-header {
    text-align: left;
  }
  .recently-added-carousel {
    &__item {
      &__tile {
        width: 50%;
        &__content {
          &__frame {
            width: 160px;
            height: 160px;
          }
        }
      }
    }
  }
}
@media @smallDesktop {
  .recently-added-carousel {
    &__item {
      &__tile {
        width: 33.33%;
        margin: 0 3% 0 3%;
      }
    }
  }
}
@media @bigDesktop {
  .recently-added-carousel {
    &__item {
      &__tile {
        &__content {
          &__frame {
            width: 240px;
            height: 240px;
          }
        }
      }
    }
  }
}
// CAROUSEL RECENTLY ADDED END //

// POINTS START //
.points-section {
  margin: 0 0 8px 0;
  padding: 8px 0 24px 0;
  &__main {
    margin: 0 8px 24px 8px;
    font-size: 16px;

    p {
      a {
        margin: 0 3px;
      }
    }

    &__list {
      list-style-type: none;
      &__item {
        padding-top: 4px;
        &__points {
          margin-left: 4px;
          color: @yellow;
          font-weight: bold;

          svg {
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
}
@media @smallDesktop {
  .points-section {
    padding: 8px 0 44px 0;
    margin: 0 0 8px 0;
    &__main {
      margin: 0 0 24px 0;
    }
  }
}
// POINTS END //

// LEAUGUES START //
.leagues-section {
  color: @white;
  background-color: @black;
  width: 100%;
  margin: 0 0 44px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -32px;
    left: 15%;
    right: 15%;
    width: 70%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      transparent,
      rgb(48, 49, 51),
      transparent
    );
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -40px;
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
    background-color: @gray;
    border: 1px solid @black;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px white, 0 0 0 4px white;
  }

  h2 {
    background-color: @white;
  }
  h3 {
    background-color: @white;
    margin-bottom: 24px;
    text-align: center;
    margin: 24px 20%;
  }
  &__main {
    display: flex;
    flex-wrap: wrap;
    &__half {
      width: 50%;
      &__list {
        list-style-type: none;
        padding: 0 8px 0 24px;
        &__item {
          margin-bottom: 8px;
          &__number {
            font-size: 18px;
            font-weight: bold;
            margin-right: 4px;
            font-family: "Roboto Slab", serif;
            letter-spacing: 2px;
          }
          &__points {
            margin-left: 4px;
            font-weight: bold;
            display: block;
            width: 100%;
          }
        }
      }
      &__image-container {
        width: 100%;
        height: 100%;
        background-color: @white;
        display: flex;
        justify-content: center;
        img {
          object-fit: contain;
          max-width: 90px;
        }
      }
    }
  }
}

@media @bigMobile {
  .leagues-section {
    &__main {
      &__half {
        &__list {
          padding: 0 8px 0 40px;
          &__item {
            &__points {
              display: inline;
              width: auto;
            }
          }
        }
        &__image-container {
          img {
            object-fit: contain;
            max-width: none;
          }
        }
      }
    }
  }
}

@media @smallDesktop {
  .leagues-section {
    &__main {
      &__half {
        &__list {
          padding: 0 8px 0 40px;
          &__item {
            &__points {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

// LEAUGUES END //

// RANDOM SECTION START //
.random-section {
  margin-bottom: 24px;
  &__left {
    width: 220px;
    height: 220px;
    margin: 0 auto;
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    &__title {
    }

    &__icon {
      color: @yellow;
      font-size: 44px;
      margin-bottom: 36px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 24px;
      text-align: center;
    }
    &__link {
      display: block;
    }
  }
}

@media @smallDesktop {
  .random-section {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    &__left {
      width: 60%;
      height: 100%;
      padding: 24px;
    }

    &__right {
      width: 40%;
    }
  }
}

// RANDOM SECTION END //

// ADD SONG SECTION END //
.add-song-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &__left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    order: 2;
    &__title {
    }

    &__icon {
      color: @yellow;
      font-size: 44px;
      margin-bottom: 36px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 24px;
      text-align: center;
    }
    &__link {
      display: block;
    }
  }

  &__right {
    width: 220px;
    height: 220px;
    margin: 0 auto;
    order: 1;
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }
}

@media @smallDesktop {
  .add-song-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__left {
      width: 40%;
      order: 1;
    }

    &__right {
      width: 60%;
      height: 100%;
      padding: 24px;
    }
  }
}
// ADD SONG SECTION END //

// YOU HAVE VOTE SECTION START //
.you-have-vote {
  margin-top: 24px;
  margin-bottom: 24px;
  &__left {
    width: 220px;
    height: 220px;
    margin: 0 auto;
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    &__title {
    }

    &__icon {
      color: @yellow;
      font-size: 44px;
      margin-bottom: 36px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 24px;
      text-align: center;
    }
    &__link {
      display: block;
    }
  }
}
@media @smallDesktop {
  .you-have-vote {
    display: flex;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 48px;
    &__left {
      width: 60%;
      height: 100%;
      padding: 24px;
    }

    &__right {
      width: 40%;
    }
  }
}
// YOU HAVE VOTE SECTION END //

// RIVALRY SECTION START //
.rivalry-section {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;

  &__left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    order: 2;
    &__title {
    }

    &__icon {
      color: @yellow;
      font-size: 44px;
      margin-bottom: 36px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 24px;
      text-align: center;
    }
    &__link {
      display: block;
    }
  }

  &__right {
    width: 220px;
    height: 220px;
    margin: 0 auto;
    order: 1;
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }
}

@media @smallDesktop {
  .rivalry-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 48px;
    &__left {
      width: 40%;
      order: 1;
    }

    &__right {
      width: 60%;
      height: 100%;
      padding: 24px;
    }
  }
}
// RIVALRY SECTION END //
