.ant-input {
  padding: 11px 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-container {
  width: auto;
  height: auto;
  position: relative;
  &__icon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
    color: @gray;
    &:hover {
      color: @black;
      animation-name: shake;
      animation-duration: 0.5s;
    }
  }
}

.input-container label {
  padding: 11px 14px;
  color: #afbdcf;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
}

input:focus + label {
  color: @yellow;
  top: -12px;
  left: 10px;
  background: #ffffff;
  padding: 0px 5px 0px 5px;
}

input:not([value=""]):not(:focus) + label {
  color: #ddd;
  top: -12px;
  left: 10px;
  background: #ffffff;
  padding: 0px 5px 0px 5px;
}

.ant-form-item-has-error label {
  color: @red !important;
}

input[type="text"]:focus,
input[type="password"]:focus {
  outline: none;
}

.ant-form-item {
  margin-bottom: 0;
}

// YEAR PICKER //

.ant-picker {
  border-color: #ddd;
  width: 100%;
  padding: 11px 14px;
  border-radius: 5px;
}

// SELECT //
.ant-select-selector {
  height: auto !important;
  border-color: #ddd !important;
  width: 100% !important;
  padding: 4px 14px !important;
  border-radius: 5px !important;
}

.ant-select-item-option-active {
  background-color: @light-gray !important;
}
.ant-select-item-option-selected {
  font-size: bold;
  background-color: @light-gray !important;
  border-left: 1px solid @black;
  border-right: 1px solid @black;
}
