.detail-rate {
  background-color: @light-gray;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  &__single {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
      font-size: 16px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 100px;
    }

    &__number {
      font-size: 64px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 108px;
    }

    &__star {
      font-size: 290px;
      color: @yellow;
    }
  }
}

@media (min-width: 327px) {
  .detail-rate {
    &__single {
      &__label {
        font-size: 18px;
      }
    }
  }
}

@media @smallDesktop {
  .detail-rate {
    flex-direction: row;
    justify-content: center;
    &__single {
      width: auto;
      padding: 0 15px;
      margin-right: 0;

      &:last-child {
        margin-right: 0;
      }

      &__label {
        font-size: 14px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 70px;
      }

      &__number {
        font-size: 48px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 73px;
      }

      &__star {
        font-size: 210px;
        color: @yellow;
      }
    }
  }
}

@media @bigDesktop {
  .detail-rate {
    &__single {
      padding: 0;
      margin-right: 28px;

      &__label {
        font-size: 16px;
        top: 90px;
      }

      &__number {
        font-size: 72px;
        top: 93px;
      }

      &__star {
        font-size: 280px;
        color: @yellow;
      }
    }
  }
}
