.message--error {
  span {
    font-size: 18px;
    padding-right: 4px;
    position: absolute;
    top: 2px;
    left: 0;
  }
  color: @red;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-left: 24px;
}

.message--warn {
  span {
    font-size: 18px;
    padding-right: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
  color: orange;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.message--info {
  span {
    font-size: 18px;
    padding-right: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
  color: blue;
  display: flex;
  align-items: flex-end;
  position: relative;
}
