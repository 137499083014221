.article-tile-skeleton {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  height: 460px;
  margin-bottom: 48px;
  cursor: pointer;
  border: 1px solid @light-gray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;

  &__img-container {
    width: 100%;
    height: 40%;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    background: @light-gray;
  }

  &__text {
    width: 100%;
    padding: 8px 16px 16px 16px;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background: @white;
      position: absolute;
      top: -3px;
      left: 0;
    }

    &__title {
      width: 100%;
      height: 32px;
      margin-bottom: 16px;
    }

    &__title-second {
      width: 80%;
      height: 32px;
      margin-bottom: 24px;
    }

    &__teaser {
      &__line {
        width: 100%;
        height: 20px;
        margin-bottom: 8px;
      }
    }
  }

  &__tags {
    position: absolute;
    right: auto;
    left: 16px;
    bottom: 12px;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    height: 22px;
    overflow: hidden;
    &__single-tag {
      border-radius: 5px;
      padding: 2px 4px;
      margin: 0 3px;
      font-weight: 400;
      height: 16px;
      width: 44px;
    }
  }
}

@media @bigMobile {
  .article-tile-skeleton {
    height: 440px;
  }
}
@media @smallDesktop {
  .article-tile-skeleton {
    width: 80%;
    max-width: 840px;
    margin-left: 4%;
    height: 440px;

    &__img-container {
      height: 50%;
    }

    &__text {
      padding: 12px 24px 24px 24px;

      &__title {
        width: 80%;
        height: 32px;
        margin-bottom: 16px;
      }

      &__title-second {
        display: none;
      }

      &__teaser {
        margin-top: 24px;
      }
    }

    &__tags {
      right: 24px;
      left: auto;
      bottom: 12px;
      height: auto;
    }
  }
}
