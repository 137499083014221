.single-result-skeleton {
  border-top: 8px solid @gray;
  border-bottom: 4px solid @gray;
  -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.1);
  border-right: none;
  border-left: none;
  transition: 0.4s;
  width: 100%;
  margin-bottom: 24px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    &__frame {
      width: 80px;
      height: 80px;
      border: 1px solid @gray;
      padding: 8px;
      margin-bottom: 8px;
      background-color: @light-gray;
    }
    &__description {
      padding-left: 16px;
      width: calc(100% - 80px);
      &__title {
        content: "";
        width: 60%;
        height: 25px;
        margin-bottom: 8px;
      }
      &__property {
        margin-bottom: 4px;
        content: "";
        width: 40%;
        height: 16px;
      }
      &__property.shorter {
        width: 30%;
      }
    }
    &__rate {
      padding-left: 0px;
      margin-top: 8px;
      &__block {
        display: flex;
        justify-content: center;
        background-color: @light-gray;
        width: 83px;
        height: 50px;
        &__star {
          font-size: 24px;
          color: @yellow;
          position: relative;
          top: 4px;
          padding-left: 4px;
          display: flex;
          align-items: center;
        }
      }
      &__votes {
        background-color: @black;
        width: 83px;
        height: 26px;
        padding: 2px;
      }
    }
  }
}

@media (min-width: 460px) {
  .single-result-skeleton {
    &__content {
      justify-content: center;
    }
  }
}

@media (min-width: 555px) {
  .single-result-skeleton {
    &__content {
      justify-content: space-around;
      &__frame {
        width: 124px;
        height: 124px;
      }

      &__description {
        padding-left: 0px;
        width: 150px;
        &__title {
          width: 80%;
        }
        &__property {
          width: 60%;
        }
        &__property.shorter {
          width: 50%;
        }
      }
      &__rate {
        padding-left: 0px;
      }
    }
  }
}

@media @smallDesktop {
  .single-result-skeleton {
    &__content {
      justify-content: flex-start;
      &__frame {
        width: 164px;
        height: 164px;
      }
      &__description {
        padding-left: 16px;
        margin-right: 132px;
        width: 200px;

        &__title {
          width: 100%;
          height: 35px;
          margin-bottom: 16px;
        }

        &__property {
          height: 18px;
          margin-bottom: 8px;
        }
      }
      &__rate {
        position: absolute;
        top: 24px;
        right: 24px;
        &__block {
          width: 89px;
          height: 62px;
          &__star {
            font-size: 28px;
          }
        }
        &__votes {
          width: 89px;
          height: 30px;
        }
      }
    }
  }
}
@media @desktop {
  .single-result-skeleton {
    width: 75%;
  }
  .single-result-skeleton.showPosition {
    margin-left: 25%;
  }
}

@media @bigDesktop {
  .single-result-skeleton.showPosition {
    margin-left: 30%;
  }
  .single-result-skeleton {
    margin-bottom: 32px;
    width: 70%;
    &__content {
      &__frame {
        width: 200px;
        height: 200px;
      }
      &__description {
        width: 300px;
        padding-left: 16px;
        margin-right: 132px;

        &__title {
          height: 45px;
          margin-bottom: 24px;
        }
        &__property {
          height: 20px;
          margin-bottom: 12px;
        }
      }
      &__rate {
        &__block {
          width: 100px;
          height: 100px;
          &__star {
            font-size: 36px;
          }
        }
        &__votes {
          width: 100px;
          height: 33px;
        }
      }
    }
  }
}
