.add-song {
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
}

@media @bigMobile {
  .add-song {
    margin: 0 auto;
  }
}

@media @smallDesktop {
  .add-song {
    width: 60%;
    margin: 0 auto;
  }
}

// ALBUM START //

.add-album-container {
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
  &__list {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 24px;

    &__item {
      display: flex;
      flex-wrap: wrap;
      &__feats {
        overflow: hidden;
        white-space: nowrap;
        max-width: 140px;
        text-overflow: ellipsis;
      }
      .ant-list-item-action {
        display: flex;
        align-items: center;
        margin-left: 0;
      }
      &__text {
        max-width: 30%;
        word-break: break-word;
      }
      a {
        color: @black;
        transition: 0.4;

        svg {
          font-size: 20px;
          padding-right: 4px;
          position: relative;
          top: 4px;
        }
        &:hover {
          color: @yellow;
        }
      }
      .star-container {
        svg {
          color: @yellow;
          position: relative;
          top: 2px;
        }
      }
    }
    &__header-skeleton {
      width: 150px;
      height: 24px;
    }
    &__content-skeleton {
      width: 250px;
      height: 22px;
    }
    &__right-skeleton {
      width: 200px;
      height: 20px;
    }
  }
}

@media @bigMobile {
  .add-album-container {
    margin: 0 auto;
    &__list {
      &__item {
        &__text {
          max-width: 40%;
        }
      }
    }
  }
}

@media @smallDesktop {
  .add-album-container {
    width: 60%;
    margin: 0 auto;
    &__list {
      &__item {
        &__feats {
          max-width: 320px;
        }
        &__text {
          max-width: 60%;
        }
      }
    }
  }
}

@media @bigDesktop {
  .add-album-container {
    &__list {
      &__item {
        &__feats {
          max-width: 800px;
        }
      }
    }
  }
}

// ALBUM END //
