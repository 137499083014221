.about-artist-skeleton {
  &__title {
    height: 30px;
    width: 60%;
  }

  &__list {
    &__title {
      height: 22px;
      width: 50%;
    }
    &__item {
      .skeleton {
        width: 40%;
        height: 22px;
      }
    }
  }
}

@media @smallDesktop {
  .about-artist-skeleton {
    &__title {
      height: 30px;
      width: 40%;
    }

    &__list {
      &__title {
        height: 22px;
        width: 30%;
      }
      &__item {
        .skeleton {
          width: 20%;
          height: 22px;
        }
      }
    }
  }
}

@media @desktop {
  .about-artist-skeleton {
    &__title {
      height: 40px;
    }
  }
}
