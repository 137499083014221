.about-song {
  &__wrapper {
    display: flex;
    flex-direction: column;
    &__info-block {
      order: 2;
      &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      &__link {
        span {
          margin-left: 4px;
          font-size: 24px;
        }
      }
      &__rate {
        display: flex;
        justify-content: center;
        align-items: center;
        .number {
          font-size: 24px;
          margin-left: 8px;
        }
        .anticon-star {
          font-size: 24px;
          margin-left: 8px;
          color: @yellow;
          position: relative;
          top: 2px;
        }
      }
    }
    &__ad-sense {
      width: 100%;
      height: 100px;
      margin-bottom: 24px;
      order: 3;
    }
    &__iframe {
      width: 90%;
      height: 250px;
      margin: 12px auto 32px auto;
      order: 3;
    }
  }
}

.rate-form {
  &__single-rate {
    border-bottom: 1px dashed @yellow;
    .message--error {
      position: relative;
      bottom: 24px;
    }
  }
}

.lyrics-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-size: 32px;
    margin-bottom: 8px;
  }
  &__words-number {
    color: @gray;
    font-weight: 700;
    margin-bottom: 32px;
  }
  &__external-html {
    height: 300px;
    overflow-y: hidden;
    position: relative;

    &__block {
      margin-bottom: 32px;
      max-width: 450px;
    }
    &__block p,
    &__block li,
    &__block h1,
    &__block h2,
    &__block h3,
    &__block span,
    &__block strong,
    &__block i {
      font-family: "Mulish", sans-serif !important;
      letter-spacing: 0.5px;
      text-align: center;
      font-size: 16px;
    }

    &::after {
      content: "";
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 140px;
    }
  }

  &__external-html.expanded {
    height: 100%;

    &::after {
      display: none;
    }
  }

  &__show-more {
    font-weight: 700;
    cursor: pointer;
  }
}

.review-song {
  &__username {
    max-width: 300px;
  }
}

.review-overview {
  &__single {
    margin-bottom: 24px;
    .ant-comment-inner {
      padding: 10px;
      -webkit-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.3);
      -moz-box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.3);
      box-shadow: -6px 7px 37px 0px rgba(112, 112, 112, 0.3);
      border-left: 4px solid rgba(112, 112, 112, 1);
    }
    .ant-comment-content-author-name {
      width: 100%;
    }
    .ant-comment-content-author-name > * {
      color: @yellow;
    }

    .ant-comment-content-detail {
      margin: 0 8px;
    }

    .ant-comment-actions {
      display: flex;
      justify-content: center;
    }
  }

  .anticon-star {
    color: @yellow;
    font-size: 20px;
  }
}

.anticon-star:before {
  content: none !important;
}

.alredy-reviewed-baner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//must be diffrent with then breakpoints (SPECIAL CASE)
@media (min-width: 1030px) {
  .about-song {
    &__wrapper {
      flex-direction: row;
      margin-bottom: 44px;
      &__info-block {
        order: 2;
        padding-left: 24px;
        margin-top: 12px;
        font-size: 16px;
        width: 45%;
        &__info {
          justify-content: flex-start;
        }
        &__rate {
          justify-content: flex-start;
          position: relative;
          bottom: 16px;
          .number {
            font-size: 32px;
          }
          .anticon-star {
            font-size: 32px;
          }
        }
      }
      &__ad-sense {
        width: 90%;
        order: 3;
      }
      &__iframe {
        width: 55%;
        height: 300px;
        order: 3;
      }
    }

    &__frame {
      order: 1;
    }
  }
}

@media (min-width: 1200px) {
  .rate-form {
    display: flex;
    flex-wrap: wrap;
    &__single-rate {
      width: 33.33%;
      border-bottom: none;
      border-right: 1px dashed @yellow;
      &:nth-child(3) {
        border-right: none;
      }
    }

    &__type {
      width: 100%;
    }
    &__btn-container {
      width: 100%;
    }
  }
}
