.login-page {
  &__login {
    width: 100%;
    margin: 0 auto;
    &__submit {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 18px;
      }
    }
  }
  &__register-block {
    text-align: center;
    margin-top: 16px;
    a {
      margin-left: 4px;
    }
  }
}

@media @bigMobile {
  .login-page {
    &__login {
      width: 90%;
      &__submit {
        width: 90%;
      }
    }
  }
}

@media @smallDesktop {
  .login-page {
    &__login {
      width: 50%;
      &__submit {
        width: 60%;
      }
    }
  }
}
